.autocomplete {
  position: absolute;
  z-index: 9999;
  border: 1px solid #65d8ff;
  box-shadow: 0 0 0 0.2rem rgba(0, 170, 228, 0.25);
}

.autocomplete button.bg-dark:hover {
  background-color: #00aae4 !important;
}
