.rbt {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.rbt-menu {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #343a40 !important;
}
.rbt-menu .dropdown-item {
  color: rgba(255, 255, 255, 0.5) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
}
.rbt-menu .dropdown-item:hover,
.rbt-menu .dropdown-item:focus {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}
mark,
.mark {
  padding: 0 !important;
  color: #ffffff;
  background-color: #1c9d5b !important;
}
