.counter-group {
  background: rgba(255, 255, 255, 0.1);
}
.counter-group-item {
  background: transparent !important;
  padding: 0 !important;
  flex: 1 1 auto;
  text-align: center !important;
}

.counter-group .btn {
  font-size: 0.7rem;
  padding: 0.125rem 0.25rem;
}

.counter-btns {
  font-size: 0.5rem;
  padding: 0.125rem 0.25rem;
}
