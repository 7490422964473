.report {
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Helvetica Neue,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
  margin-bottom: 2rem;
  font-weight: lighter !important;
}

.screen-report {
  display: block;
}

.print-report {
  display: none;
}

@media print {
  .pagebreak {
    margin-top: 3rem;
    page-break-after: always;
  }

  .print-report {
    display: block;
  }

  .screen-report {
    display: none;
  }
}
