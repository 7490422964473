/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  padding: 1rem 0;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgba(0, 170, 228, 0.5);
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -5px; /* Centers thumb on the track */
  border-radius: 0.5rem; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #00aae4;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  border: 1px solid #00aae4;
  outline: 3px solid #00aae4;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: rgba(0, 170, 228, 0.5);
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0.5rem; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #00aae4;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #00aae4;
  outline: 3px solid #00aae4;
  outline-offset: 0.125rem;
}
