/* All popups */

.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #343a40;
}

.leaflet-popup-content-wrapper {
  background-color: #343a40 !important;
  color: #fff;
}

/* Popups for Counter Badge */

.leaflet-popup-content-wrapper:has(.counter-badge-popup) {
  border-radius: 0;
}

.leaflet-popup-content:has(.counter-badge-popup) {
  margin: 0.25rem;
}

/* Popups for Menu */

.leaflet-popup:has(.menu-popup) button {
  font-size: 0.875rem;
  border: none;
}

.leaflet-popup-content-wrapper:has(.menu-popup) {
  border-radius: 10;
}

.leaflet-popup-content:has(.menu-popup) {
  margin: 0.5rem;
}
