@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  display: inline-block;
  -webkit-animation: anim-rotate 2s infinite linear;
  animation: anim-rotate 2s infinite linear;
}

.center-all {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  color: #fff;
  font-size: 3rem;
}

.preloader.show .preloader-dialog {
  -webkit-transform: none;
  transform: none;
}
.preloader.fade .preloader-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition:
    transform 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

.preloader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.preloader-backdrop.fade {
  opacity: 0;
}

.preloader-backdrop.show {
  opacity: 0.5;
}
