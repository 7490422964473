/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("fonts/roboto-v20-latin-300.eot"); /* IE9 Compat Modes */
  src:
    local("Roboto Light"),
    local("Roboto-Light"),
    url("fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/roboto-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/roboto-v20-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("fonts/roboto-v20-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("fonts/roboto-v20-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    local("Roboto"),
    local("Roboto-Regular"),
    url("fonts/roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/roboto-v20-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("fonts/roboto-v20-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("fonts/roboto-v20-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
html {
  --newColourblindMarkerColour: transparent;
  --newMarkerColour: transparent;
  --replacementColourblindMarkerColour: transparent;
  --replacementMarkerColour: transparent;
}

body {
  padding-top: 42px;
  padding-bottom: 0;
}
/* html,
body {
  overflow-x: hidden;
} */
html,
body,
#root,
#map {
  height: 100%;
  width: 100%;
}
body {
  font:
    12px/1.5 "Helvetica Neue",
    Arial,
    Helvetica,
    sans-serif !important;
}
img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.bg-primary-alpha {
  background: rgba(0, 170, 228, 0.9);
}

.bg-dark-alpha {
  background: rgba(52, 58, 64, 0.9);
}

.bg-white-alpha {
  background: rgba(255, 255, 255, 0.18);
}

.bg-white-beta {
  background: rgba(255, 255, 255, 0.18);
}

.text-black {
  color: rgba(255, 255, 255, 0.5) !important;
}

.navbar {
  padding: 0.5rem !important;
}
.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-header {
  margin-top: 93px;
  padding: 0.75rem 0.75rem 0.5rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.form-body {
  margin-top: 93px;
  padding: 0.75rem 0.5rem;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 73px !important;
}

.offcanvas-collapse,
.feedbackForm {
  background: rgba(52, 58, 64, 0.9) !important;
}

.navbar-nav-scroll {
  max-width: 100%;
  height: 3rem;
  overflow: hidden;
}

.navbar-nav .nav-link {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  color: #cbbde2;
}

.navbar-header {
  color: #cbbde2 !important;
}

.navbar-nav-scroll .navbar-nav {
  padding-bottom: 2rem;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 192px) {
  body {
    padding-top: 63px;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      body {
        padding-top: 58px;
      }
    }
  }
  .form-header {
    margin-top: 0;
    position: static;
  }
  .form-body {
    margin-top: 0;
    padding: 0.75rem;
  }
  .navbar {
    padding: 0.5rem 1rem;
  }
  .navbar-brand {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .offcanvas-collapse {
    position: fixed;
    top: 62px; /* Height of navbar (non-safari) */
    z-index: 1049;
    bottom: 0;
    right: 0;
    width: 0;
    /* width: 20%; */
    /* overflow-y: auto; */
    /* visibility: hidden; */
    opacity: 1;
    transition:
      opacity 0.3s ease-in-out,
      -webkit-transform 0.3s ease-in-out;
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out,
      -webkit-transform 0.3s ease-in-out;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .offcanvas-collapse {
        top: 56px; /* Height of navbar for safari */
      }
    }
  }
  .offcanvas-collapse.open {
    /* visibility: visible; */
    /* -webkit-transform: translateX(-100%);
    transform: translateX(-100%); */
    margin-right: 0;
    width: 400px;
  }
  .offcanvas-collapse.hide {
    opacity: 0;
  }
  .offcanvas-collapse .btn-panel::after {
    display: inline-block;
    vertical-align: 0.5em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    border-left: none;
  }
  .offcanvas-collapse.open .btn-panel::after {
    border-right: none;
    border-left: 0.3em solid;
  }

  .form-header {
    margin-top: 0;
    padding: 1.5rem 1.5rem 1rem;
  }

  .leaflet-bottom .leaflet-control {
    margin-bottom: 10px !important;
  }

  .btn-panel {
    width: 25px;
    height: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
  }
  .btn-panel {
    right: auto;
    left: -25px;
  }
}

.sidebar-scroll {
  height: calc(100vh - 117px) !important;
}

.form-control[readonly] {
  background-color: transparent !important;
  border-color: transparent !important;
}

.form-control[readonly] {
  background-color: rgba(52, 58, 64, 0.5) !important;
  border-color: rgba(52, 58, 64, 0.5) !important;
}

.form-control[disabled] {
  background-color: transparent !important;
  border-color: #343a40 !important;
}

select {
  /* needed */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* SVG background image */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23FFFFFF'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-color: #efefef;
}

button.dropdown-item:focus {
  outline: none;
}
.text-zombie {
  color: #54c571 !important;
}
.text-bronze {
  color: #cd7f32 !important;
}
.text-grey {
  color: #cccccc !important;
}
.text-raspberry {
  color: #fe6a6a !important;
}
.text-rose {
  color: #ed91b6 !important;
}
.text-baby-pink {
  color: #ffd7fc !important;
}
.text-lilac {
  color: #df93ff !important;
}
.text-duck-egg {
  color: #bee1e3 !important;
}
.text-baby-blue {
  color: #b0cffe !important;
}
.text-blue-grotto {
  color: #b09cd6 !important;
}
.text-hawkes-blue {
  color: #cce4ff !important;
}
.text-smoke {
  color: #c3c2e2 !important;
}
.text-lavender {
  color: #ba97ff !important;
}
.text-mint {
  color: #a7fcdb !important;
}
.text-sea-green {
  color: #a6e8aa !important;
}
.text-pea {
  color: #ccfca6 !important;
}
.text-lemon {
  color: #f9ff91 !important;
}
.text-sepia {
  color: #997d65 !important;
}
.text-coral {
  color: #f1956e !important;
}
.text-peach {
  color: #fcb96b !important;
}
.text-apricot {
  color: #ead376 !important;
}
.text-cyan {
  color: #b0d9d3 !important;
}
.text-citron {
  color: #9fa91f !important;
}
.text-cafe {
  color: #a67b5b !important;
}
.text-frost {
  color: #6d9bc3 !important;
}

.dropdown-item {
  text-align: right;
  background-color: transparent !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.filter-menu {
  background-color: rgba(52, 58, 64, 0.9) !important;
  text-align: right;
}

.btn-sidebar-tabs {
  color: #fff !important;
  background-color: transparent;
  border-color: transparent;
}

.btn-sidebar-tabs:not(:disabled):not(.disabled):active,
.btn-sidebar-tabs:not(:disabled):not(.disabled).active,
.show > .btn-sidebar-tabs.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.list-group-item {
  color: #fff;
  padding: 0.5rem 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

a.list-group-item {
  cursor: pointer;
  background-color: transparent !important;
}

.list-group-item-btn-grp {
  color: #fff;
  background-color: transparent !important;
}

a.list-group-item:hover,
a.list-group-item.active {
  color: inherit !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.list-group .list-group > .list-group-item {
  border-left: 1.5rem solid rgba(0, 0, 0, 0.125) !important;
}

.list-group .list-group-item.indented {
  border-left: 1.5rem solid rgba(0, 0, 0, 0.125) !important;
}

.list-group-item-icon {
  display: inline-block;
  text-align: right;
  width: 2rem;
}

.btn-sidebar-tabs {
  color: #fff;
}

.btn-sidebar-tabs-legacy {
  background-color: #220639 !important;
  border-color: #220639 !important;
}

.btn-sidebar-tabs-connectgrid {
  background-color: #220639 !important;
  border-color: #220639 !important;
}

.btn-sidebar-tabs-visnet {
  background-color: #1c1f3e !important;
  border-color: #1c1f3e !important;
}

.btn-sidebar-tabs:hover {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-sidebar-tabs:not(:disabled):not(.disabled):active,
.btn-sidebar-tabs:not(:disabled):not(.disabled).active,
.show > .btn-sidebar-tabs.dropdown-toggle {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-sidebar-tabs:focus {
  box-shadow: none !important;
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  display: inline-block;
  -webkit-animation: anim-rotate 2s infinite linear;
  animation: anim-rotate 2s infinite linear;
}

.sidebar-open .leaflet-control-scale {
  right: 430px;
}

.superthead th {
  border-bottom: 0 !important;
}

.th-separator {
  border-left: 1px solid #000;
}

.fixed-top {
  z-index: 1050 !important;
}

.navbar {
  font-size: 1rem;
  z-index: 1050 !important;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  color: #fff !important;
  text-decoration: none !important;
  background-color: #00aae4 !important;
}

.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus {
  color: #0d0d0d !important;
  text-decoration: none !important;
  background-color: #f7f7f9 !important;
}

.show-nearby-assets span {
  user-select: none;
  cursor: pointer;
}
.show-nearby-assets[disabled] span {
  opacity: 0.7;
  pointer-events: none;
}

.yellow {
  font-size: 80px;
  color: yellow;
}

.consumer-up-down {
  background-color: #4a4a49;
}

.consumer-up-down:hover {
  background-color: #1d2124;
}

.file-section:hover {
  cursor: pointer;
}

.navbar-icon:hover {
  cursor: pointer;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.linkbox-correct {
  height: 1px;
  width: 50px;
}

.linkbox-start {
  width: 10px;
  height: 1px;
}

.linkbox-end {
  width: 20px;
  height: 1px;
}

.linkbox-open-line {
  transform: rotate(-45deg);
  width: 20px;
  height: 1px;
}

.linkbox-connectivity-button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.linkbox-status-title {
  font-size: 1rem;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.expandable-form {
  margin-left: 10px;
  margin-top: 20px;
}

.expandable-form label {
  width: 100%;
  margin: 0px;
}

.expandable-form .form-group {
  margin-top: 10px;
}

.expandable-form label span {
  display: inline-block;
  width: 15px;
}

.confirm-button {
  transition: background-color 0.6s ease !important;
}
.updated-button {
  background-color: #105a34 !important;
  border-color: #072817 !important;
}
.updated-button span {
  margin-right: 10px;
}

.horizontal-buttons button {
  padding: 0.1rem 0.1rem;
  font-size: 0.9rem;
}

#wrapper:focus-visible {
  outline: none;
}

.clickable-nav-links {
  cursor: pointer;
}

.clickable-nav-links .active {
  font-weight: bold;
}

.clickable-nav-links a {
  color: black;
}

.report .form-control {
  background-color: white;
  color: black;
}

.report .form-control:focus {
  background-color: white;
  color: black;
}

.input-button {
  font-size: 0.5rem !important;
  padding: 0.5rem !important;
  background-color: white !important;
  color: black !important;
  border: solid 1px black !important;
  border-left: 0 !important;
}

.table-sortable th {
  cursor: pointer;
}
.table-custom-legacy tr.active {
  font-weight: bold;
}
.table-custom-legacy tr.active .badge {
  margin-left: 5px;
}
.table-custom-legacy button.btn-sm {
  line-height: 0.5;
}
.table-custom-connectgrid tr.active {
  font-weight: bold;
}
.table-custom-connectgrid tr.active .badge {
  margin-left: 5px;
}
.table-custom-connectgrid button.btn-sm {
  line-height: 0.5;
}
.table-custom-visnet tr.active {
  font-weight: bold;
}
.table-custom-visnet tr.active .badge,
#version-table tr.active .badge {
  margin-left: 5px;
}
#version-table tr.active .badge {
  padding-top: 4px;
}
.table-custom-visnet button.btn-sm {
  line-height: 0.5;
}

.clickable-label {
  cursor: grab;
  pointer-events: auto;
}
