.resultMarker {
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.tablePopup {
  margin-bottom: 0px !important;
  font-size: 0.875rem;

  tr {
    td {
      border: none;
      span {
        padding-right: 0.25rem;
      }
      span:last-child {
        padding-right: 0rem;
      }
    }
  }
}

.tablePopupUnit {
  font-weight: 300;
  font-size: 0.625rem;
}

.tablePopupPlaceholder {
  font-size: 0.875rem;
}

.tablePopupCloseButton {
  position: absolute;
  top: 12px;
  right: 10px;
  height: 16px;
  width: 16px;
  div {
    margin-left: -7px;
    margin-top: -4px;
  }
}

.countMarker {
  z-index: 1990 !important;
}

.iconMarker {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 2rem;
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  opacity: 0.5;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  color: #ffffff !important;
}

.iconMarker-sz-1 {
  font-size: 0.75rem !important;
}

.iconMarker-sz-1-5 {
  font-size: 1rem !important;
}

.iconMarker-sz-2 {
  font-size: 1.5rem !important;
}

.iconMarker-sz-3 {
  font-size: 2rem !important;
}

.iconMarker-sz-3-with-lct-enabled {
  font-size: 1.5rem !important;
  padding-bottom: 1.35rem !important;
}

.iconMarker-sz-4 {
  font-size: 2.5rem !important;
}

.smallIconMarker {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 1rem;
  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  opacity: 0.5;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.smallIconMarkerX1 {
  font-size: 1rem;
}

.smallIconMarkerX2 {
  font-size: 1.75rem;
}

.smallIconMarkerX3 {
  font-size: 2.5rem;
}

.smallIconMarkerX4 {
  font-size: 3.25rem;
}

.smallIconMarkerX5 {
  font-size: 4rem;
}

.iconMarker.connect:hover {
  background-color: #3388ff !important;
}

.iconMarker.handle:hover {
  background-color: rgba(255, 255, 255, 1) !important;
}

.smallIconMarker.connect:hover {
  color: #3388ff !important;
}

.square {
  font-size: 1.5rem;
}

.badge-colourblind-colour {
  background-color: var(--newColourblindMarkerColour);
  border-color: transparent;
}

.badge-colour {
  background-color: var(--newMarkerColour);
  border-color: transparent;
}

.badge-replacement-colourblind-colour {
  background-color: var(--replacementColourblindMarkerColour);
  border-color: transparent;
}

.badge-replacement-colour {
  background-color: var(--replacementMarkerColour);
  border-color: transparent;
}

.icon-colour {
  color: var(--newMarkerColour);
}

.icon-colourblind-colour {
  color: var(--newColourblindMarkerColour);
}

.icon-replacement-colour {
  color: var(--replacementMarkerColour);
}

.icon-replacement-colourblind-colour {
  color: var(--replacementColourblindMarkerColour);
}

.edit {
  opacity: 1;
}
.draw,
.select,
.select-group,
.select-results,
.remove-group {
  opacity: 1;
  box-shadow: none;
}

.leaflet-container.ringfence {
  cursor: pointer;
}

.remove {
}
/* .connect {
  border-color: #ffcc00;
  box-shadow: 0px 0px 6px 6px rgba(255, 204, 0, 0.5);
} */

.handle {
  opacity: 1;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 2px solid #3388ff;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.icon-primary {
  border-color: #7fd4f1 !important;
}

.text-primary-alpha {
  color: #7fd4f1 !important;
}

.text-light-alpha {
  color: #ffffff !important;
}
