.control {
  position: absolute;
}
.control-top {
  top: 0;
}
.control-bottom {
  bottom: 0;
}
.control-left {
  left: 0;
}
.control-right {
  right: 0;
}
.control-margin-top-sm {
  margin-top: 0.5rem;
}
.control-margin-bottom-sm {
  margin-bottom: 0.5rem;
}
.control-margin-left-sm {
  margin-left: 0.5rem;
}
.control-margin-right-sm {
  margin-right: 0.5rem;
}
.control-margin-top-md {
  margin-top: 0.5rem;
}
.control-margin-bottom-md {
  margin-bottom: 0.5rem;
}
.control-margin-left-md {
  margin-left: 0.5rem;
}
.control-margin-right-md {
  margin-right: 0.5rem;
}
